//https://getbootstrap.com/docs/4.5/getting-started/theming/#sass-options

$enable-gradients: true;

@import "lib/bootstrap/scss/bootstrap.scss";

/*
$studie:      fade_out($info, 0.9);
$atelier:     fade_out($primary, 0.9);
$sound:       fade_out($primary, 0.9);
$montage:     fade_out($dark, 0.9);
$transport:   fade_out($danger, 0.9);
 */

$loadunload: rgb(123, 104, 238);

$studie:      $info;
$atelier:     $primary;
$sound:       $primary;
$montage:     $dark;
$transport:   $danger;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "studie":     $studie,
    "atelier":    $atelier,
    "sound":      $sound,
    "montage":    $montage,
    "transport":  $transport,
    "loadunload": $loadunload
  ),
  $theme-colors
);

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

.text-loadunload {
  color: $loadunload !important;
}


// main.scss
.planning-row {
  //height: 50px;

  .p {
    height: 50px;
  }
  .col {
    //border-left: 1px solid lightgray;
    //border-right: 1px solid lightgray;
  }
  /*
  :hover {
    background-color: #f8f9fa; // bg-light
  }
   */
  .today {
    //background-color: fade_out($primary, 0.75);
    background-color: lighten($primary, 40%);
  }
}

.planning-row-info {
  border-bottom: 1px solid $gray-300;
  border-right: 1px solid $secondary;
}

.row.header {
  //border-bottom: 1px solid #007bff;
  //height: 40px;
  /*
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

   */
  .today {
    font-weight: bold;
    /*
    border-width: 1px;
    border-style: solid;
    border-color: $primary;
     */
  }
}
.resizer {
  cursor: ew-resize;
}
.grab {
  cursor: grab;
}
.modal-body {
  .spacer {
    border-bottom: 1px solid #dee2e6; // $modal-header-border-color
  }
}
.modal-footer {
  .col.d-flex.justify-content-end {

    // Easily place margin between footer elements
    > :not(:first-child) { margin-left: .25rem; }
    > :not(:last-child) { margin-right: .25rem; }
  }
}

.saturday, .sunday {
  background-color: $gray-200;
}
.holiday {
  background-color: lightyellow;
}
.sunday {
  border-right: 1px solid #999999;
}
.today {
  // background-color: palegoldenrod;
  border-color: $primary;
}
.planned {
  //background-color: #ADC09F;
  //border-right-color: #ADC09F !important;
}
.inactiveMonth {
  color: lightgray;
  //background-color: lightgray;
}

.planning-nav-link {
  display: block;
  /*padding: $nav-link-padding-y $nav-link-padding-x;
    border-bottom: 1px solid $body-bg;

   */

  /*
    @include hover-focus {
      text-decoration: none;
    }

   */

  /* Disabled state lightens text
    &.disabled {
          color: $nav-link-disabled-color;
         pointer-events: none;
         cursor: default;
     }
   */
}

.top-48 {
  top: 48px;
}

// ----------- FROM offcanvas bootstrap example

.nav-scroller {
  //position: relative;
  z-index: 2;
  //height: 2.75rem;
  //overflow-y: hidden;

  .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}
.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.planned-item {
  position: absolute;
  z-index: 1;
  //background-color: #007bff;
  height: 70%;
  padding: 10% 10%;

  p {
    padding: 0;
    margin: 0;
  }
}
.planned-right {
  //border-right: 2px red solid !important;
}
.planned-left {
  //border-left: 2px red solid !important;
}
.planned-left:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  //border-right-color: $info;
  border-width: 5px;
  margin-top: -5px;
}
.planned-right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  //border-left-color: $info;
  border-width: 5px;
  margin-top: -5px;
}
.planned-right.bg-info:after {
  border-left-color: $info;
}
.planned-right.bg-primary:after {
  border-left-color: $primary;
}
.planned-right.bg-dark:after {
  border-left-color: $dark;
}
.planned-left.bg-info:after {
  border-right-color: $info;
}
.planned-left.bg-primary:after {
  border-right-color: $primary;
}
.planned-left.bg-dark:after {
  border-right-color: $dark;
}

// override JQuery UI Resizable css
.ui-resizable {
  position: absolute !important;
}

/*
.bootstrap-datetimepicker-widget table th.next {
  content: "\203A";
  color: #212529;
}
*/


.bootstrap-datetimepicker-widget table th.next {
  display: inline-block;
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}
.bootstrap-datetimepicker-widget table th.prev {
  display: inline-block;
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

.autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  width: max-content;
}